.aboutUs-column {
    background: #f4f4f4;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border: solid 8px white;
}

.aboutUs img {
    width: 100%;
    margin-bottom: 15px;
}

.aboutUs {
    margin-bottom: 20px;
}
